import React from 'react';
import './Header.css'

const Header = () => {
  return (
    <div>
      <div className="sub-header">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <ul className="left-info">
                <li>
                  <a href="/contact"><i className="fa fa-phone"></i>+91 8788587234</a>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="right-icons" >
                <li><a href="https://www.facebook.com/cozfinancials/"><i className="fa fa-facebook"></i></a></li>
                <li><a href="https://www.youtube.com/@cozfinancials21"><i className="fa fa-youtube"></i></a></li>
                <li><a href="https://x.com/i/flow/login?redirect_after_login=%2Fcpbadgujar"><i className="fa fa-twitter"></i></a></li>
                <li><a href="https://www.linkedin.com/company/cozfin-financial-asset-services/"><i className="fa fa-linkedin"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <header className="">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img className="header-img" src="assets/images/cozfin.png" alt="Cozfin Logo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{width:'7vh',marginTop:'1vh',marginRight:'-1vh',borderRadius:'2vh'}}
            >
              <span className="navbar-toggler-icon" ></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item" >
                  <a className="nav-link" href="/" >Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about">About Us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/services">Our Services</a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/"
                    id="calculatorsDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Calculators
                  </a>
                  <div className="dropdown-menu" aria-labelledby="calculatorsDropdown">
                    <a className="dropdown-item" href="/sip-calculator">SIP Calculator</a>
                    <a className="dropdown-item" href="/goal-calculator">Custom Goal Calculator</a>
                    <a className="dropdown-item" href="/retirement-calculator">Retirement Calculator</a>
                    <a className="dropdown-item" href="/inflation-calculator">Inflation Calculator</a>
                  </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/blogs">Blogs</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact">Contact Us</a>
                </li>             
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
