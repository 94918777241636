import React, { useState } from "react";
import "./Blog.css";


const Blogs = () => {
  const blogData = [
    {
      id: 1,
      title: "A Beginner's Guide to Mutual Fund Investing",
      image: "assets/images/mutualfund.jpg",
      content: [
        {
          answer:"Are you new to investing and wondering where to start? Mutual funds are a popular investment option that can help you grow your wealth over time. In this beginner's guide, we'll cover the basics of mutual fund investing and provide you with a solid foundation to get started."
        },
        {
          question: "What are Mutual Funds?",
          answer: "A mutual fund is a type of investment vehicle that pools money from many investors to invest in a diversified portfolio of stocks, bonds, and other securities. Mutual funds are managed by professional fund managers who invest the money on behalf of the investors.",
        },
        {
          question: "How Do Mutual Funds Work?",
          answer: `1. Investors put their money into a mutual fund.
2. The fund manager invests the money in a variety of assets, such as stocks, bonds, and commodities.
3. The fund earns returns in the form of dividends, interest, and capital gains.
4. The returns are distributed to the investors in proportion to their investment.`,
        },
        {
          question:"Types of Mutual Funds?",
          answer:`1. Equity funds: Invest in stocks and aim to provide long-term capital appreciation.
2. Debt funds: Invest in bonds and aim to provide regular income.
3. Hybrid funds: Invest in a mix of stocks and bonds.
4. Index funds: Track a specific stock market index, such as the S&P BSE Sensex.`
        }
      ],
    },
    {
      id: 2,
      title: "The Importance of Health Insurance",
      image: "assets/images/lifeinsurance.jpeg",
      content: [
        {
           answer:"In today's fast-paced world, maintaining good health is more important than ever. However, with the rising costs of medical care, it can be challenging to afford quality healthcare when you need it most. This is where health insurance comes in – a vital safety net that protects both your health and finances."
        },
        {
          question: "What is Health Insurance?",
          answer: "Health insurance is a type of insurance policy that covers the cost of medical and surgical expenses incurred by the policyholder. In exchange for a premium, the insurance provider agrees to reimburse the policyholder for medical expenses, up to a certain limit.",
        },
        {
          question: "Why Do You Need Health Insurance?",
          answer: `1. Protection against unexpected medical expenses: Medical emergencies can arise at any time, and the costs can be crippling. Health insurance ensures that you're prepared for unexpected medical expenses.
2. Access to quality healthcare: With health insurance, you can afford to seek medical attention from reputable hospitals and healthcare providers.
3. Reduced financial burden: Medical expenses can be a significant financial burden. Health insurance helps to alleviate this burden, ensuring that you can focus on your recovery rather than worrying about the cost.
4. Tax benefits: In many countries, health insurance premiums are eligible for tax deductions, providing an added incentive to invest in a policy.`,
        },
        {
          question: "Types of Health Insurance Policies ?",
          answer: `1. Individual health insurance: Designed for individuals, these policies provide coverage for medical expenses incurred by the policyholder.
2. Family floater health insurance: These policies provide coverage for the entire family, with a single premium payment.
3. Group health insurance: Typically offered by employers, these policies provide coverage for employees and their families.
4. Critical illness insurance: These policies provide coverage for specific critical illnesses, such as cancer or heart disease.`,
        },
      ],
    },
    {
      id: 3,
      title: "The Importance of Life Insurance",
      image: "assets/images/Life-Insurance-Family-1.jpeg",
      content: [
        {
           answer:"Life is unpredictable, and unexpected events can happen to anyone. While it's impossible to predict the future, you can take steps to ensure that your loved ones are financially protected, even if you're no longer around. This is where life insurance comes in – a vital safety net that provides financial security for your family in the event of your passing."
        },
        {
          question: "Why is Life Insurance Important?",
          answer: "Life insurance is a type of insurance policy that provides a financial safety net for your loved ones in the event of your death. In exchange for a premium, the insurance provider agrees to pay a death benefit to your beneficiaries, which can be used to cover funeral expenses, outstanding debts, and ongoing living expenses.",
        },
        {
          question:"Why Do You Need Life Insurance?",
          answer:`1. Financial security for your loved ones: Life insurance ensures that your family is financially protected, even if you're no longer around to provide for them.
2. Funeral expenses: The cost of a funeral can be significant, and life insurance can help cover these expenses.
3. Outstanding debts: If you have outstanding debts, such as a mortgage or credit card debt, life insurance can help pay off these debts, ensuring that your loved ones aren't left with a financial burden.
4. Ongoing living expenses: Life insurance can provide a financial safety net for your loved ones, helping them cover ongoing living expenses, such as food, housing, and education.`
        },
        {
          question:"Why Do You Need Life Insurance?",
          answer:`1. Financial security for your loved ones: Life insurance ensures that your family is financially protected, even if you're no longer around to provide for them.
2. Funeral expenses: The cost of a funeral can be significant, and life insurance can help cover these expenses.
3. Outstanding debts: If you have outstanding debts, such as a mortgage or credit card debt, life insurance can help pay off these debts, ensuring that your loved ones aren't left with a financial burden.
4. Ongoing living expenses: Life insurance can provide a financial safety net for your loved ones, helping them cover ongoing living expenses, such as food, housing, and education.`
        },
      ],
    },
  ];

  const [expandedBlogId, setExpandedBlogId] = useState(null);

  const toggleReadMore = (id) => {
    setExpandedBlogId((prevId) => (prevId === id ? null : id));
  };

  return (
    <div>
      <div className="page-heading header-text">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Blogs</h1>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <div className="blogs-container">
        {blogData.map((blog) => (
          <div className="blog-card" key={blog.id}>
            <img src={blog.image} alt={blog.title} className="blog-image" />
            <div className="blog-content">
              <h3 className="blog-title">{blog.title}</h3>
              {expandedBlogId === blog.id ? (
                blog.content.map((item, index) => (
                  <div key={index} className="blog-question-answer">
                    <strong className="blog-question">{item.question}</strong>
                    <p className="blog-answer">{item.answer}</p>
                  </div>
                ))
              ) : (
                <p className="blog-description">{blog.content[0].answer}</p>
              )}
              <button
                className="read-more-button"
                onClick={() => toggleReadMore(blog.id)}
              >
                {expandedBlogId === blog.id ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blogs;
